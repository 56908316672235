import axios from 'axios';
import * as dd from 'dingtalk-jsapi';
import { getApiUrl } from '../Httputil/httpsutil'
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {updateUserInformation} from "../redux/getUserInformation/getUserInformation"
import { connect } from "react-redux";

import "./authdd.css"

const fetchData = async (url) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const redirctDDQrcode = async () =>{
  const configJson = await fetchData('/config.json');
  let appKey = configJson["appKey"]
  let loginUrl = configJson["redirctUrl"]
  // console.log("9999999999999",appKey,loginUrl)
  // 跳转至钉钉扫码登录页面
  window.location.href = `https://oapi.dingtalk.com/connect/qrconnect?appid=${appKey}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${encodeURI(loginUrl)}`;
}

const AuthPage =  (props) => {
  const [userInfo, setUserInfo] = useState({ name: '', userid: "" })
  const [loginState, setLoginState] = useState(0)
  const navigate = useNavigate()
  useEffect(() => {
    console.log("not in dd env", dd.env.platform)   
    try {
      if (dd.env.platform === "notInDingTalk") {
        // 解析URL参数
        const urlParams = new URLSearchParams(window.location.search);
        
        // 检查是否包含code参数
        if (urlParams.has('code')) {
          const codeValue = urlParams.get('code');
          console.log("44", props, props.updateLoginInformation);
          props.updateLoginInformation(
            {
              code:codeValue,
            })
            navigate("/home")
          console.log('URL中包含code参数，参数值为:', codeValue);
          // 在这里可以执行相应的操作，例如处理 code 参数等
          
        } else {
          console.log('URL中没有包含code参数');
          try {
            setLoginState(0)
            redirctDDQrcode()
          } catch (error) {
            // 处理错误
            setLoginState(-1)
            console.error('An error occurred:', error);
          }
        }    
      } else {
        dd.runtime.permission.requestAuthCode({
          corpId: "ding913622bcbad0908df5bf40eda33b7ba0", //三方企业ID
          onSuccess: function (result) {
            console.log("68",result.code);
            props.updateLoginInformation(
              {
                code:result.code,
               
              })
              navigate("/home")
          //   const header = {
          //     headers: {
          //         Code: result.code, // 这里添加Code字段
          //     }
          // };
          //   axios.post(getApiUrl() + "/login", "", header)
            
          //     .then(response => {
          //       const { code, data } = response.data
          //       if (code === 0) {
          //         navigate("/home")
          //         setLoginState(1)
          //       } else {
          //         setLoginState(-1)
          //         console.log("response err code ", code)
          //       }
          //     })
          //     .catch(error => {
          //       setLoginState(-1)
          //       alert(JSON.stringify(error))
          //     })
          },
          onFail: function (err) {
            alert(JSON.stringify(err))
            setLoginState(-1)
          }
        });
      }
    } catch (error) {
      console.log("not env", error)
    }
  }, [])

  return <div className="Auth">
    <img src={'https://img.alicdn.com/imgextra/i3/O1CN01Mpftes1gwqxuL0ZQE_!!6000000004207-2-tps-240-240.png'} className="avatar" />
    {/* {getResult(loginState, userInfo.name)} */}
    {/* {<p>{userInfo.name}: 登陆成功</p>} */}
  </div>
}
const mapDispatchToProps = (dispatch) => ({
  updateLoginInformation(payload) {
    dispatch(updateUserInformation(payload));
  },
});

export default connect(null,mapDispatchToProps)(AuthPage);

function getResult(state, name) {
  return state === 0 ? <p>认证中(Loading)</p> : state == 1 ? <p>{name}: 登陆成功</p> : <p>授权认证失败</p>
}