import { configureStore } from '@reduxjs/toolkit';
import persistedReducer from './persistConfig'; // 注意这里是默认导入
import { persistStore } from 'redux-persist';

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
