import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import userInformationReducer from './getUserInformation/getUserInformation';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userInformation'],
};

const rootReducer = combineReducers({
  userInformation: userInformationReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer; // 注意这里是默认导出
