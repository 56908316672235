import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  code:""
  };

export const setUserInformation = createSlice({
    name: "userInformation",
    initialState,
    reducers: {
        updateUserInformation: (state, action) => {
            state.code = action.payload.code;
            console.log("1212", state, action.payload.code);
          },
    },
  });
  
  export const { updateUserInformation} = setUserInformation.actions;
  
  export default setUserInformation.reducer;