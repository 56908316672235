import React, {useEffect} from 'react';
import { Button, Layout, Menu, Tabs ,Avatar, message  } from 'antd';
import './home.css'
import { useState } from 'react';
import {getApiUrl}  from "../Httputil/httpsutil"
import axios from 'axios';
import * as dd from 'dingtalk-jsapi';
import { updateUserInformation } from "../redux/getUserInformation/getUserInformation";

import { connect } from "react-redux";

const { Header, Content  } = Layout;

const Home = (props) => {

    const getCret = () => {
      console.log("17", props.userInformation);
      axios.post(getApiUrl() +  "/getcert", { code: props.userInformation.code })
      .then(response => {
        if(response.status == 200) {
          var element = document.createElement('a');
              element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.data));
              element.setAttribute('download', "id_rsa-cert.pub");
              document.body.appendChild(element);
              element.click();
          // if (dd.env.platform === "notInDingTalk") {
          //     var element = document.createElement('a');
          //     element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.data));
          //     element.setAttribute('download', "id_rsa-cert.pub");
          //     document.body.appendChild(element);
          //     element.click();
          // } else {
          //   dd.biz.util.downloadFile({
          //     url: response.data,  
          //     name: 'id_rsa-cert.pub', 
          //     onSuccess: function() {
          //       console.log('文件下载成功');
          //     },
          //     onFail: function(err) {
          //       console.error('文件下载失败', err);
          //     }
          //   });
          // }
        } else {
          message.error("没有权限")
        }
      })
      .catch(error => {
        message.error("没有权限")
      })
     
    }
    return (
        <Layout >
            <Content
                style={{
                  display: "flex", 
                  alignItems: "center", 
                  justifyContent: "center", 
                  height:"100vh"
                }}
              >
           <Button type='primary' onClick={getCret}>证书申请下载</Button>
        </Content>
        </Layout>
    );
};
const mapStateToProps = (state) => {
    return {
      userInformation: state.userInformation, // 确保这个路径与 store 中的 reducer 匹配
    };
  };
const mapDispatchToProps = (dispatch) => ({
    updateLoginInformation(payload) {
      dispatch(updateUserInformation(payload));
    },
  });
export default connect(mapStateToProps,mapDispatchToProps)(Home);
